import React, { Fragment, PureComponent } from "react";
import SubscriptionAddToCartButton from "./SubscriptionAddToCartButton";
import { CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import Parser from 'html-react-parser';
import ReactImageFallback from "react-image-fallback";
import { Row, Col } from "react-bootstrap";
class SubscriptionItemList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        document.getElementById("scrollTop").scrollIntoView() // Scroll to Top
        this.changeVariation();
    }

    changeVariation = () => {
        const product = this.props.product;
        const variation = product.hasOwnProperty("variations") ? product.variations[product.first_month] : null;

        let firstRegularPriceDisplay = false;
        let regular_price = 0;
        let sale_price = 0;
        if (variation) {
            if (parseFloat(variation.sale_price) > 0) {
                firstRegularPriceDisplay = true;
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation.regular_price;
                sale_price = variation.regular_price;
            }
        }

        this.setState({
            firstMonthVariationId: (variation && variation.hasOwnProperty('variation_id')) ? variation.variation_id : null,
            firstRegularPrice: CURRENCY_FORMAT(regular_price),
            firstSalePrice: CURRENCY_FORMAT(sale_price),
            firstRegularPriceDisplay: firstRegularPriceDisplay,
            // AddToSubscription State for Subscription
            itemCount: product.item_count,
            subsProductId: product.product_id,
            subsProductName: product.title,
            subsImage: product.thumb_image,
            subsFirstVariationId: (variation) ? variation.variation_id : '',
            subsFirstVariationName: (variation) ? variation.variation_name : '',
            subsFirstQuantity: (variation) ? variation.quantity : 0,
            subsSubscriptionMsg: "Every 1 Month(s)",
            subsDiscountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
            inStock: (variation) ? variation.quantity : 0,
        });
    }

    render() {

        const product = this.props.product;

        return (
            <Fragment>
                 <div className="order_pro_list add_item_blk white_bg">
                    <Row>
                        <Col md={8}>
                            <div className="d-flex align-items-center order_pro_left_det">
                                <div className="order_pro_img">
                                <ReactImageFallback
                                                src={product.hasOwnProperty('list_image') ? product.list_image : null}
                                                fallbackImage={require('../../../Assets/images/preloader.gif')}
                                                initialImage={require('../../../Assets/images/preloader.gif')}
                                                alt=''
                                                className="" />
                                </div>
                                <div className="order_pro_det pl_16">
                                    <h2 className="text_black d-flex mb_10 font_20 let_spa_2 fw_el_bold"> {product.hasOwnProperty('title') ? product.title : null} </h2>
                                    <h3 className="text_black d-flex mb_10 font_16 let_spa_2 fw_ar_reg"> {product.hasOwnProperty('short_description') ? Parser(product.short_description) : null}</h3>
                                    <div className="d-flex align-items-center">
                                        <label className="subscri_blk mr_19 d-flex align-items-center checkbox_label mb_0 radio_label" htmlFor="">
                                            <div className="sub_rgt">
                                                <div className="d-flex align-items-center mb_5">
                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">Subscribe and Save {product.hasOwnProperty('subscription_save_percentage') ? product.subscription_save_percentage : null} %  </h3>
                                                    {/* <img src={SubDropdown} alt="SubDropdown" className="ml_19" /> */}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <span className="d-block font_14 text_black fw_ar_reg line_through let_spa_2">{CURRENCY_FORMAT(this.state.subsDiscountPrice)}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className="order_pro_btn d-flex flex-column align-items-end justify-content-between">
                            <div className="d-flex align-items-center justify-content-end mb_26">
                                {
                                    (this.state.firstRegularPrice != '$0.00 USD') ?
                                        <span className="font_16 d-block items_text let_spa_2 fw_ar_reg line_through">{this.state.firstRegularPrice}</span>
                                        : ""
                                }

                                <span className="font_16 d-block text_black let_spa_2 fw_ar_reg pl_16">{this.state.firstSalePrice}</span>
                            </div>
                            <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                <SubscriptionAddToCartButton subsState={this.state} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default SubscriptionItemList;