import React, { Fragment, PureComponent } from 'react';
import { AJAX_REQUEST, CURRENCY_FORMAT, SET_STORAGE, GET_STORAGE, REMOVE_STORAGE } from "../../../Constants/AppConstants";
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import history from "../../../history";
import SubscriptionCartProductList from './SubscriptionCartProductList';
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';
import AlertWrepper from "../../Common/AlertWrapper";
import { Row, Col } from "react-bootstrap";
import Parser from 'html-react-parser';

class SubscriptionCart extends PureComponent {
    constructor(props) {
        super(props)
        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }
        this.state = {
            taxStatus: settings ? settings.tax_status : 0,
            subscription_id: parseInt(this.props.match.params.id),
            products: [],
            currentItems: [],
            shipping_method: "",
            shipping_charge: 0,
            address_1: "",
            postcode: "",
            city: "",
            state: "",
            country: "",
            loading: true,
            saving: false,
            isLoading: false,
            success_alert_wrapper_show: false,
        }
        document.title = "Subscription Cart-Prestige Labs";
    }

    componentDidMount() {
        document.getElementById("scrollTop").scrollIntoView() // Scroll to Top
        this.getSubscriptionProducts();
    }

    getSubscriptionProducts = () => {
        let cart_items = [];
        let newSubsItems = [];
        let currentSubsItems = [];

        if (GET_STORAGE('subscriptionItem')) {
            newSubsItems = JSON.parse(GET_STORAGE('subscriptionItem'))

            if (newSubsItems.length < 0 || newSubsItems == '') {
                history.push("/my-account/related-subscription/" + this.state.subscription_id);
            }

            newSubsItems.forEach(function (item, key) {
                let newItem = {
                    cart_product_id: item.subs_product_id,
                    cart_product_name: item.subs_product_name,
                    cart_image: item.subs_image,
                    cart_variation_id: item.subs_variation_id,
                    cart_variation_name: item.subs_variation_name,
                    cart_sale_price: 0,
                    subscription: "yes",
                    cart_subscription_msg: item.subs_subscription_msg,
                    cart_discount_price: item.subs_discount_price,
                    quantity: item.quantity,
                }
                cart_items.push(newItem);
            });
        }

        // Get existing order details
        const data = { subscription_id: this.state.subscription_id }
        AJAX_REQUEST("POST", "subscription/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                currentSubsItems = results.response.data.subscription.items;
                let shipping_address = results.response.data.shipping_address;

                currentSubsItems.forEach(function (item, key) {
                    let newItem = {
                        cart_product_id: item.product_id,
                        cart_product_name: item.name,
                        cart_image: item.product_thumbnail,
                        cart_variation_id: item.variation_id,
                        cart_variation_name: "",
                        cart_sale_price: 0,
                        subscription: "yes",
                        cart_subscription_msg: "",
                        cart_discount_price: item.total_price,
                        quantity: item.quantity,
                    }
                    cart_items.push(newItem);
                });

                this.setState({
                    cart_items: cart_items,
                    products: newSubsItems,
                    currentItems: currentSubsItems,

                    shipping_charge: results.response.data.subscription.shipping_charge,
                    shipping_method: results.response.data.subscription.shipping_method,
                    shipping_cost: results.response.data.subscription.shipping_charge,

                    address_1: shipping_address.street_address,
                    postcode: shipping_address.zip,
                    city: shipping_address.city,
                    state: shipping_address.state,
                    country: shipping_address.country,
                    payment_method : results.response.data.subscription.payment_method,

                    loading: false
                });
                this.getTax();
            }
        });
    }

    // Get and calculate tax if applicable
    getTax = () => {

        this.setState({
            subscription_tax_amount: 0,
            subscription_tax_info: '',
        })

        if (this.state.taxStatus == 1) {
            let taxData = {
                address_1: this.state.address_1,
                postcode: this.state.postcode,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                shipping_cost: this.state.shipping_cost,
                cart_items: this.state.cart_items
            }
            AJAX_REQUEST("POST", "order/getTax", taxData).then(results => {
                if (parseInt(results.response.code) === 1000 && results.response.data != '') {
                    this.setState({
                        subscription_tax_amount: results.response.data.subscription_tax_amount,
                        subscription_tax_info: results.response.data.subscription_tax_info,
                    });
                }
            });
        }
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false
            });
        }.bind(this), timedata);
    }

    deleteItem = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete item?")) {
            let subscriptionItem = JSON.parse(GET_STORAGE('subscriptionItem'));
            if (subscriptionItem.splice(row_id, 1)) {
                SET_STORAGE("subscriptionItem", JSON.stringify(subscriptionItem));
                this.getSubscriptionProducts();
                document.querySelector("body").scrollIntoView();
                this.setState({ success_alert_wrapper_show: true });
                this.timeOut(5000);
            }
        }
    }

    onSubmitHandler = (e) => {
        e.preventDefault();

        // Create new items array
        let items = [];
        JSON.parse(GET_STORAGE('subscriptionItem')).forEach(function (item, key) {
            let newItem = {
                product_id: item.subs_product_id,
                variation_id: item.subs_variation_id,
            }
            items.push(newItem);
        });

        let data = {
            subscription_id: this.state.subscription_id,
            subscription_items: items,
            subscription_tax_amount: this.state.subscription_tax_amount,
            subscription_tax_info: this.state.subscription_tax_info,
        }
        this.setState({ isLoading: true, saving: true, });
        AJAX_REQUEST("POST", "subscription/addItem", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                if(this.state.payment_method == 'paypal') {
                    window.location.href  = results.response.checkoutUrl;
                }else{
                    history.push("/my-account/related-subscription/" + this.state.subscription_id + "?is_subscription_orders_page=1");
                }
                REMOVE_STORAGE("subscriptionItem");
                this.setState({
                    isLoading: false,
                    saving: false,
                });
                
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                    success_alert_wrapper_show: false,
                    saving: false,
                });
            }
        });

    }

    render() {

        let subTotal = 0;

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="cart_page_container aff_cart_page_container">
                                <form onSubmit={this.onSubmitHandler} id="cartForm" className='w-100'>
                                    <div className="container">
                                        <h2 className="font_24 mb_11 text_black text-uppercase fw_el_semi">Shopping CART</h2>
                                        <nav aria-label="breadcrumb" className="mb_13">
                                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb cart_breadcrubmb">
                                                <li className="breadcrumb-item font_16 choose_text fw_ar_reg">
                                                    <NavLink to="/">Home</NavLink>
                                                </li>
                                                <li className="breadcrumb-item font_16 choose_text fw_ar_reg" aria-current="page">
                                                    &gt; Shopping Cart
                                                </li>
                                            </ol>
                                        </nav>
                                        <div className="row cart_row">
                                            <Col md={8}>
                                                <div className="cart_left_det">
                                                    {
                                                        (this.state.error_meg) ?
                                                        <AlertWrepper errors_data={Parser(this.state.error_meg)} success_alert_wrapper_show={this.state.success_alert_wrapper_show} />
                                                        :
                                                        null
                                                    }
                                                    <AlertWrapperSuccess errors_data="Subscription item removed successfully" success_alert_wrapper_show={this.state.success_alert_wrapper_show} />
                                                    <h2 className="font_16 mb_11 text_black text-uppercase fw_ar_semi">NEWLY ADDED ITEM(S)</h2>
                                                    <div className="cart_left_det">
                                                        {
                                                            (this.state.products.length <= 0) ? <div className="cart_pro_list d-flex align-items-center justify-content-center aff_cart_pro_list"><h5 className='m-0 font_16 text_black text-center fw_ar_reg'>No Record Found</h5></div> :
                                                                this.state.products.map(function (product, key) {
                                                                    subTotal = Number(subTotal) + Number(product.subs_discount_price);
                                                                    // Added item delete methos
                                                                    product.deleteItem = this.deleteItem;
                                                                    product.row_id = key;

                                                                    return (
                                                                        <SubscriptionCartProductList key={key} product={product} />
                                                                    )
                                                                }.bind(this))
                                                        }
                                                    </div>
                                                    <h2 className="font_16 mb_11 text_black text-uppercase fw_ar_semi">CURRENT SUBSCRIPTION ITEM(S)</h2>
                                                    <div className="cart_left_det">
                                                        {
                                                            (this.state.currentItems.length <= 0) ? <div className="cart_pro_list d-flex align-items-center justify-content-center aff_cart_pro_list"><h5 className='m-0 font_16 text_black text-center fw_ar_reg'>No Record Found</h5></div> :
                                                                this.state.currentItems.map(function (product, key) {

                                                                    subTotal = Number(subTotal) + Number(product.total_price);

                                                                    product.subs_product_name = product.name;
                                                                    product.subs_discount_price = product.total_price;
                                                                    product.subs_image = product.product_thumbnail;

                                                                    return (
                                                                        <SubscriptionCartProductList key={key} deleteItem="false" product={product} />
                                                                    )
                                                                }.bind(this))
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="cart_rgt_blk white_bg">
                                                    <ul className="pro_cart_det">
                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(subTotal)}</span>
                                                        </li>
                                                        <li className="d-flex align-items-center flex-wrap mb_4">
                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.shipping_charge)}</span>
                                                        </li>
                                                        <li className="d-flex align-items-center flex-wrap mb_3">
                                                            <span className="d-block let_spa_2 font_12 fw_ar_reg text_default">{this.state.shipping_method}</span>
                                                        </li>
                                                        {
                                                            (this.state.taxStatus == 1) ?
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_4">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.subscription_tax_amount)}</span>
                                                                    </li>
                                                                </Fragment>
                                                                : null
                                                        }
                                                        <li className="d-flex align-items-center flex-wrap mb_26">
                                                            <span className="total_span let_spa_2 d-block font_16 fw_ar_bold">Total</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_bold">
                                                                {
                                                                    CURRENCY_FORMAT(Number(subTotal) + Number(this.state.shipping_charge) + Number(this.state.subscription_tax_amount))
                                                                }
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <button disabled={this.state.isLoading} className="w-100 d-flex justify-content-center shop_more take_quiz_btn font_16 text_white fw_ar_reg">{this.state.saving ? "Please Wait..." : "Confirm and Proceed"}</button>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default SubscriptionCart;
