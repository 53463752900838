import React, { PureComponent, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import $ from "jquery";
import OrderIconActive from '../../../Assets/images/customer_images/orders_icon_active.svg'
import SubscriptionIconActive from '../../../Assets/images/customer_images/subscription_icon_active.svg'
import AddressIconActive from '../../../Assets/images/customer_images/address_icon_active.svg'
import AccountIconActive from '../../../Assets/images/customer_images/account_detail_icon_active.svg'
import CardIconActive from '../../../Assets/images/customer_images/my_card_icon_active.svg'
import OrderIcon from '../../../Assets/images/customer_images/orders_icon.svg'
import SubscriptionIcon from '../../../Assets/images/customer_images/subscription_icon.svg'
import AddressIcon from '../../../Assets/images/customer_images/address_icon.svg'
import AccountIcon from '../../../Assets/images/customer_images/account_detail_icon.svg'
import CardIcon from '../../../Assets/images/customer_images/my_card_icon.svg'

class CustSideMenuLink extends PureComponent {

    render() {
        return ( 
            <Fragment>
                <li>
                    <NavLink to="/my-account/orders" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={OrderIcon} alt="orderIcon" className='inactive_icon' /><img src={OrderIconActive} alt="OrderIconActive" className='active_icon' /> Orders</NavLink>
                </li>
                <li>
                    <NavLink to="/my-account/subscriptions" activeClassName="active d-flex align-items-center font_16 fw__ar_bold"><img src={SubscriptionIcon} alt="SubscriptionIcon" className='inactive_icon' /><img src={SubscriptionIconActive} alt="SubscriptionIconActive" className='active_icon' />  Subscriptions</NavLink>
                </li>
                <li>
                    <NavLink to="/my-account/edit-addresses" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={AddressIcon} alt="AddressIcon" className='inactive_icon' /><img src={AddressIconActive} alt="AddressIconActive" className='active_icon' />  Addresses</NavLink>
                </li>
                <li>
                    <NavLink to="/my-account/edit-account-details" activeClassName="active d-flex align-items-center font_16 fw_ _ar_bold"><img src={AccountIcon} alt="AccountIcon" className='inactive_icon' /><img src={AccountIconActive} alt="AccountIconActive" className='active_icon' /> Account Details</NavLink>
                </li>
                <li>
                    <NavLink to="/my-account/my-cards" activeClassName="active d-flex align-items-center font_16 fw_ _ar_bold"><img src={CardIcon} alt="CardIcon" className='inactive_icon' /><img src={CardIconActive} alt="CardIconActive" className='active_icon' /> My Cards</NavLink>
                </li>
            </Fragment>
         );
    }
}

export default  withRouter(CustSideMenuLink);