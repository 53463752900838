import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import { Row, Col, Card, Nav } from 'react-bootstrap';
import DefaultIcon from './../../../Assets/images/customer_images/default_tick_icon.svg'


class EditAddresses extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error_meg_billing: '',
            error_meg_shipping: '',
            billingAddress: "",
            shippingAddress: "",
        }
        document.title = "Addresses -Prestige Labs";
    }
    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        document.getElementById("pageTitle").innerHTML = "Addressess";
        this.getBillingAddress();
        this.getShippingAddress();
    }

    getBillingAddress = () => {
        AJAX_REQUEST("POST", "customer/billingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    billingAddress: results.response.data
                });
            } else {
                this.setState({
                    loading: false,
                    error_meg_billing: results.response.message,
                })
            }
        });
    }

    getShippingAddress = () => {
        AJAX_REQUEST("POST", "customer/shippingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    shippingAddress: results.response.data
                });
            } else {
                this.setState({
                    loading: false,
                    error_meg_shipping: results.response.message,
                })
            }
        });
    }

    render() {
        return (
            <Fragment>
                <div className="woocommerce-MyAccount-content inner_content">
                    {
                        (this.state.loading) ?
                            <div className="loading"></div>
                            :
                            <Fragment>
                                <div className="MyAccount-content address_blk">
                                <h2 className="font_26 text_black fw_ar_bold let_spa_2 mb_13">Address</h2>
                                    <div className="address_div">
                                        <Row className='account_row address_row'>
                                            <Col md={4}>
                                                <Card className="address-card">
                                                    <div className="card_header">
                                                        {
                                                            (this.state.error_meg_billing) ?
                                                                <p className="text-center">{this.state.error_meg_billing}</p>
                                                                :
                                                                <Fragment>
                                                                    <h4 className='font_14 text_black let_spa_2 fw_ar_bold mb_13'>{this.state.billingAddress.hasOwnProperty('billing_first_name') ? this.state.billingAddress.billing_first_name : ""} {this.state.billingAddress.hasOwnProperty('billing_last_name') ? this.state.billingAddress.billing_last_name : ""} </h4>
                                                                    <p className="font_12 mb_13 text_black let_spa_2 fw_ar_reg">{this.state.billingAddress.hasOwnProperty('billing_company') ? this.state.billingAddress.billing_company : ""}
                                                                        {this.state.billingAddress.hasOwnProperty('billing_address_1') ? this.state.billingAddress.billing_address_1 : ""}
                                                                        {this.state.billingAddress.hasOwnProperty('billing_address_2') ? this.state.billingAddress.billing_address_2 : ""}
                                                                        {this.state.billingAddress.hasOwnProperty('billing_city') ? this.state.billingAddress.billing_city : ""}
                                                                        {this.state.billingAddress.hasOwnProperty('billing_state') ? this.state.billingAddress.billing_state : ""}
                                                                        {this.state.billingAddress.hasOwnProperty('billing_postcode') ? this.state.billingAddress.billing_postcode : ""}
                                                                        {this.state.billingAddress.hasOwnProperty('billing_country') ? this.state.billingAddress.billing_country : ""}  </p>

                                                                    <p className="font_12 m-0 text_black let_spa_2 fw_ar_reg">
                                                                        <span className='phone_span position-relative d-inline-block'>Phone No</span>{this.state.billingAddress.hasOwnProperty('billing_phone') ? this.state.billingAddress.billing_phone : ""}
                                                                    </p>
                                                                    <p className="font_12 m-0 text_black let_spa_2 fw_ar_reg">
                                                                        <span className='phone_span position-relative d-inline-block'>Email ID</span>{this.state.billingAddress.hasOwnProperty('billing_email') ? this.state.billingAddress.billing_email : ""}
                                                                    </p>
                                                                </Fragment>
                                                        }
                                                    </div>
                                                    <div className="card_footer w-100 d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img src={DefaultIcon} alt="DefaultIcon" />
                                                            <span className="pl_4 d-block font_12 text_white let_spa_2 fw_ar_reg">Default Address</span>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            {/* <a href="#" className="font_12 text_white let_spa_2 fw_ar_reg text_underline">Delete</a> */}
                                                            <NavLink title="Edit" to="/my-account/edit-addresses/billing" className="font_12 text_white let_spa_2 fw_ar_reg text_underline ml_10">Edit</NavLink>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>

                                            <Col md={4}>
                                                <Card className="address-card">
                                                    <div className="card_header">
                                                        {
                                                            (this.state.error_meg_shipping) ?
                                                                <p className="text-center">{this.state.error_meg_shipping}</p>
                                                                :
                                                                <Fragment>
                                                                    <h4 className='font_14 text_black let_spa_2 fw_ar_bold mb_13'>{this.state.shippingAddress.hasOwnProperty('shipping_first_name') ? this.state.shippingAddress.shipping_first_name : ""} {this.state.shippingAddress.hasOwnProperty('shipping_last_name') ? this.state.shippingAddress.shipping_last_name : ""}</h4>
                                                                    <p className="font_12 mb_13 text_black let_spa_2 fw_ar_reg">
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_company') ? this.state.shippingAddress.shipping_company : ""}
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_address_1') ? this.state.shippingAddress.shipping_address_1 : ""}
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_address_2') ? this.state.shippingAddress.shipping_address_2 : ""}
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_city') ? this.state.shippingAddress.shipping_city : ""}
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_state') ? this.state.shippingAddress.shipping_state : ""}
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_postcode') ? this.state.shippingAddress.shipping_postcode : ""}
                                                                        {this.state.shippingAddress.hasOwnProperty('shipping_country') ? this.state.shippingAddress.shipping_country : ""}
                                                                    </p>
                                                                    <p className="font_12 m-0 text_black let_spa_2 fw_ar_reg">
                                                                        <span className='phone_span position-relative d-inline-block'>Phone No</span>{this.state.billingAddress.hasOwnProperty('billing_phone') ? this.state.billingAddress.billing_phone : ""}
                                                                    </p>
                                                                    <p className="font_12 m-0 text_black let_spa_2 fw_ar_reg">
                                                                        <span className='phone_span position-relative d-inline-block'>Email ID</span>{this.state.billingAddress.hasOwnProperty('billing_email') ? this.state.billingAddress.billing_email : ""}
                                                                    </p>
                                                                </Fragment>
                                                        }
                                                    </div>
                                                    <div className="card_footer set_def_card_foot w-100 d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            {/* <span className="d-block font_12 text_black text_underline let_spa_2 fw_ar_reg">Set as Default Address</span> */}
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            {/* <a href="#" className="font_12 text_black let_spa_2 fw_ar_reg text_underline">Delete</a> */}
                                                            <NavLink title="Edit" to="/my-account/edit-addresses/shipping" className="font_12 text_black let_spa_2 fw_ar_reg text_underline ml_10">Edit</NavLink>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Fragment>
                    }
                    <div className="woocommerce-notices-wrapper"></div>
                </div>
            </Fragment>
        );
    }
}

export default EditAddresses;