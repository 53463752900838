import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT, REFER_URL, PUBLIC_URL } from "../../../Constants/AppConstants";
import Pagination from '../../Common/Pagination';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DropdownIcon from '../../../Assets/images/customer_images/order_dropdown_icon.svg'
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'
import SearchIcon from '../../../Assets/images/customer_images/header_search_icon.svg'
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col } from 'react-bootstrap';
import TotalOrder from './../../../Assets/images/customer_images/total_order.svg'
import ActiveSub from './../../../Assets/images/customer_images/active_subscription.svg'
import TotalSub from './../../../Assets/images/customer_images/total_subscription.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OrderProductDetails from './OrderProductDetails';
import LikedProImg1 from './../../../Assets/images/refer_images/like_product_1.svg'
import LikedProImg2 from './../../../Assets/images/refer_images/like_product_2.svg'
import LikedProImg3 from './../../../Assets/images/refer_images/like_product_3.svg'
import LikedProImg4 from './../../../Assets/images/refer_images/like_product_4.svg'
const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 4.5,

        }
    },
};
class Orders extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            orders: [],
            error_meg: '',
            userInfo: [],
            order_status_arr: [],
            order_status: '',
            order_type: '',
            filter_loading: false,
            // Pagination Config
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
        }
        document.title = "Orders -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        document.getElementById("pageTitle").innerHTML = "Orders";
        this.getOrderStatus();
        this.getAllOrders(this.state.pagenum);
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        this.getAllOrders(pageNumber);
    }

    getAllOrders = (pageNumber) => {
        let data = {
            pagenum: parseInt(pageNumber),
            order_status: this.state.order_status,
            order_type: this.state.order_type,
        }
        AJAX_REQUEST("POST", "order/getList", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    filter_loading: false,
                    orders: results.response.data.orders,
                    error_meg: results.response.message,
                    // Pagination Config
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    pagenum: parseInt(results.response.data.pagenum),
                });
            } else {
                this.setState({
                    loading: false,
                    filter_loading: false,
                    error_meg: results.response.message,
                    orders: [],
                    // Pagination Config
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                });
            }
        });
    }

    getOrderStatus = () => {
        AJAX_REQUEST("POST", "order/getOrderStatus", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ order_status_arr: results.response.data })
            } else {
                this.setState({ order_status_arr: [] })
            }
        });
    }

    onSubmitHandler = e => {
        e.preventDefault();
        const order_status = document.getElementById("order_status").value;
        const order_type = document.getElementById("order_type").value;
        this.setState({
            loading: true,
            filter_loading: true,
            order_status: order_status,
            order_type: order_type,
        });
        this.getAllOrders(1);
    };

    render() {
        const user = this.props.user;


        return (
            <Fragment>
                <div className="woocommerce-MyAccount-content inner_content">
                    <Accordion defaultActiveKey="0">
                        {
                            (this.state.loading) ?
                                <div className="loading"></div>
                                :
                                <Fragment>
                                    <h2 className="font_26 text_black fw_ar_bold let_spa_2 mb_13">Orders</h2>
                                    <div className="mb_20 table_search table_header_search d-flex align-items-center justify-content-between">
                                        {/* <div className="col-md-3 p-0">
                                            <form action="#" className="search_form position-relative">
                                                <img src={SearchIcon} alt="search_icon" className='search_icon position-absolute' />
                                                <input type="text" className="form-control w-100 m-0" placeholder='Search' />
                                            </form>
                                        </div> */}
                                        <form onSubmit={this.onSubmitHandler} method="get" className='table_search_select d-flex align-items-center justify-content-end w-100'>
                                            <label className='items_text m-0 fw_ar_reg font_12'>Sort By</label>
                                            <select onChange={this.changeHandler} id="order_type" className="form-control" name="order_type" defaultValue={this.state.order_type} style={{ width: "200px" }}>
                                                <option value="">All</option>
                                                <option value="supplement">Product</option>
                                                <option value="meal">Meal</option>
                                            </select>
                                            {/* <label> &nbsp; Order Status &nbsp; </label> */}
                                            <select id="order_status" onChange={this.changeHandler} className="form-control" name="order_status" defaultValue={this.state.order_status} >
                                                <option value="">Order Status</option>
                                                {
                                                    this.state.order_status_arr.length <= 0 ? null :
                                                        Object.keys(this.state.order_status_arr).map(function (status, key) {
                                                            return (
                                                                <option value={status} key={key}>{status}</option>
                                                            )
                                                        })
                                                }
                                            </select>
                                            <input className="search_input_btn" type="submit" name="action" value="" />
                                        </form>
                                    </div>
                                    {
                                        (this.state.orders.length <= 0) ?
                                            <div className='no_record_found'>{this.state.error_meg}</div>
                                            :
                                            this.state.orders.map(function (order, key) {
                                                return (
                                                    <Fragment key={'o' + key}>
                                                        <Accordion.Item eventKey={'o' + key}>
                                                            <div className="order_accordatio">
                                                                <div className="order_acc_header d-flex align-items-center justify-content-between">
                                                                    <div className="left_acc d-flex w-50 align-items-center">
                                                                        <h2 className="m-0 font_26 text_black let_spa_2 fw_ar_bold">#{order.hasOwnProperty('order_id') ? order.order_id : ''}</h2>
                                                                        <span className={classNames('d-flex status_active order_status ml_20 text_white font_14 fw_ar_reg', { 'processing': order.status == "processing", 'failed': order.status == "failed", 'completed': order.status == "completed", 'refunded': order.status == "refunded", 'pending': order.status == "pending", 'onhold': order.status == "on-hold", 'chargeback': order.status == "chargeback", 'cancelled': order.status == "cancelled" })}>{order.hasOwnProperty('status') ? order.status : ''}</span>
                                                                    </div>
                                                                    <div className="rgt_acc d-flex align-items-center justify-content-end">
                                                                        {/* <NavLink className='font_16 text_black fw_ar_reg text_underline ml_18' to={`${PUBLIC_URL}serviceLogin?token=${user.token}&redirect=/reorder/${order.order_id}`}>Reorder</NavLink> */}
                                                                        <NavLink className='font_16 text_black fw_ar_reg text_underline ml_18' to={`/my-account/order-details/${order.hasOwnProperty('order_id') ? order.order_id : ''} `}>View Order Details</NavLink>
                                                                    </div>
                                                                </div>
                                                                <Accordion.Body>
                                                                    <div className="order_acc_body">
                                                                        {/* <div className="order_pro_detail d-flex align-items-center justify-content-between">
                                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                                <div className="pl_16">
                                                                                    <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'>1 X</span> AM Formula</h5>
                                                                                    <span className="font_12 items_text d-block fw_ar_reg">R.I.P. Fat -- Get RIPPED</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="right_pro d-flex flex-column align-items-end">
                                                                                <h5 className="font_14 text_black let_spa_2 fw_ar_bold mb_8 pr_10">$95.72</h5>
                                                                                <span className="d-block font_12 items_text let_spa_2">tax included</span>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="order_acc_body p-0 mb_23">
                                                                            {
                                                                                order.order_details.map(function (product, key) {
                                                                                    return (
                                                                                        <OrderProductDetails key={`op${key}`} product={product} />
                                                                                    )
                                                                                }.bind(this))
                                                                            }

                                                                        </div>
                                                                        {/* <div className="order_pro_detail d-flex align-items-center justify-content-between">
                                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                                <div className="pl_16">
                                                                                    <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'></span>{order.hasOwnProperty('order_details') ? order.order_details.item_name : ''}</h5>
                                                                                    <span className="font_12 items_text d-block fw_ar_reg">Monthly</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="right_pro d-flex flex-column align-items-end">
                                                                                <h5 className="font_14 text_black let_spa_2 fw_ar_bold mb_8 pr_10">{order.hasOwnProperty('order_details') ? CURRENCY_FORMAT(order.order_details.price) : ''}</h5>
                                                                                <span className="d-block font_12 items_text let_spa_2">tax excluded</span>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </Accordion.Body>

                                                                <div className="order_acc_footer d-flex align-items-center justify-content-between">
                                                                    <div className='d-flex align-items-center'>
                                                                        <h3 className="font_16 m-0 items_text fw_ar_reg let_spa_2">{order.hasOwnProperty('items') ? order.items : ''}</h3>
                                                                        <h3 className="font_16 text_black mb_0 ml_10 fw_ar_reg">{order.hasOwnProperty('date') ? order.date : ''}</h3>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-end">
                                                                        <h3 className="font_16 m-0 items_text fw_ar_reg">TOTAL</h3>
                                                                        <h2 className="ml_10 font_16 text_black fw_ar_bold mb_0">{order.hasOwnProperty('total') ? CURRENCY_FORMAT(order.total) : ''}</h2>
                                                                        <Accordion.Header className='ml_23 mb_0'>
                                                                            <span className='order_accor_click d-inline-block cursor-pointer'><img src={DropdownIcon} alt="dropdown_icon" /></span>
                                                                        </Accordion.Header>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Item>

                                                    </Fragment>
                                                )
                                            })
                                    }
                                    <Pagination
                                        pagenationHandle={this.pagenationHandle}
                                        total_records={this.state.total_records}
                                        total_page={this.state.total_page}
                                        per_page={this.state.per_page}
                                        pagenum={this.state.pagenum}
                                    />
                                    {/* <h2 className="font_16 mt_65 mb_18 let_spa_2 text_black fw_ar_bold">Inspired by your browsing history</h2>
                                    <div className="pro_carousel browsing_his_carousel position-relative">
                                        <OwlCarousel className='owl-theme' {...options}>
                                            <div class='item'>
                                                <div className="pro_img">
                                                    <img src={LikedProImg1} alt="like product 1" />
                                                </div>
                                                <div className="like_pro_des flex-column d-flex align-items-center">
                                                    <h3 className="font_20 text-center text_black fw_el_bold m-0">Sleeper Multiplier</h3>
                                                </div>
                                            </div>
                                            <div class='item'>
                                                <div className="pro_img">
                                                    <img src={LikedProImg2} alt="like product 2" />
                                                </div>
                                                <div className="like_pro_des flex-column d-flex align-items-center">
                                                    <h3 className="font_20 text-center text_black fw_el_bold m-0">Am Formula</h3>
                                                </div>
                                            </div>
                                            <div class='item'>
                                                <div className="pro_img">
                                                    <img src={LikedProImg3} alt="like product 3" />
                                                </div>
                                                <div className="like_pro_des flex-column d-flex align-items-center">
                                                    <h3 className="font_20 text-center text_black fw_el_bold m-0">PM Formula</h3>
                                                </div>
                                            </div>
                                            <div class='item'>
                                                <div className="pro_img">
                                                    <img src={LikedProImg4} alt="like product 4" />
                                                </div>
                                                <div className="like_pro_des flex-column d-flex align-items-center">
                                                    <h3 className="font_20 text-center text_black fw_el_bold m-0">Sleeper Multiplier</h3>
                                                </div>
                                            </div>
                                            <div class='item'>
                                                <div className="pro_img">
                                                    <img src={LikedProImg1} alt="like product 1" />
                                                </div>
                                                <div className="like_pro_des flex-column d-flex align-items-center">
                                                    <h3 className="font_20 text-center text_black fw_el_bold m-0">Sleeper Multiplier</h3>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div> */}

                                </Fragment>
                        }
                    </Accordion>

                    <div className="woocommerce-notices-wrapper"></div>
                </div>
            </Fragment>

        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps, null)(Orders);