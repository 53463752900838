import React, { Component, Fragment } from 'react';
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE } from "../../../Constants/AppConstants";
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import moment from "moment";
import AlertWrapper from '../../Common/AlertWrapper';
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';

class SubscriptionCancellationBillingUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            subscription_id: parseInt(this.props.match.params.subscription_id),
            next_payment_date: '',
            submitButton: false,

            success_alert_wrapper_show: false,
            error_alert_wrapper_show: true,
            server_message: '',
        }
        document.title = "Subscription Billing Date Update -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        document.getElementById("pageTitle").innerHTML = "SUBSCRIPTION #" + this.state.subscription_id;
        this.getSubscriptionDetails();
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                error_alert_wrapper_show: true,
                success_alert_wrapper_show: false
            });
        }.bind(this), timedata);
    }

    getSubscriptionDetails = () => {
        let data = {
            subscription_id: this.state.subscription_id
        }
        AJAX_REQUEST("POST", "subscription/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    next_payment_date: results.response.data.next_payment_date,
                    loading: false,
                });
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                });
            }
        });
    }

    openChangeDate = (e) => {
        $('.subscription_end_date_edit').hide();
        $('.subscription_end_date_edit_calender').show();

        $('.datepicker').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            startDate: moment(),
            minDate: moment(),
            locale: {
                format: 'YYYY-MM-DD'
            }
        });
    }

    closeChangeDate = (e) => {
        $('.subscription_end_date_edit').show();
        $('.subscription_end_date_edit_calender').hide();
    }

    saveChange = (e) => {
        this.setState({ submitButton: true })
        e.preventDefault();
        $('.subscription_end_date_edit').show();
        $('.subscription_end_date_edit_calender').hide();

        const next_payment_date = $('#the_date').val();
        const data = { next_payment_date: next_payment_date, subscription_id: this.state.subscription_id }

        AJAX_REQUEST("POST", "subscription/changePaymentDate", data).then(results => {
            const response = results.response;
            if (parseInt(results.response.code) === 1000) {
                const new_dated = results.response.data.next_payment_date;
                $('#show_new_date').text(new_dated);

                this.setState({
                    server_message: results.response.message,
                    success_alert_wrapper_show: true,
                    error_alert_wrapper_show: true,
                    submitButton: false,
                })
                this.timeOut(5000);
            } else {
                this.setState({
                    server_message: results.response.message,
                    success_alert_wrapper_show: false,
                    error_alert_wrapper_show: false,
                    submitButton: false,
                });
            }
        });
    }

    render() {

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="MyAccount-content">
                            <section className="woocommerce-order-details">
                                <AlertWrapper errors_data={this.state.server_message} isFormValid={this.state.error_alert_wrapper_show} />
                                <AlertWrapperSuccess errors_data={this.state.server_message} success_alert_wrapper_show={this.state.success_alert_wrapper_show} />
                                <h2 className="font_26 mb_15 text_black let_spa_2 fw_el_bold">Subscription Billing Date Update</h2>
                                <div className="sub_billing_blk white_bg">
                                    <h3 className="font_16 mb_8 text_black fw_ar_bold">Next Payment Date</h3>
                                    <h3 className="font_16 mb_30 text_black fw_ar_reg">{this.state.next_payment_date}</h3>
                                    <div className="subscription_end_date_edit float-none text-left">
                                        <a onClick={this.openChangeDate} className="cursor-pointer  font_16 text_black text_underline">Change Payment Date</a>
                                    </div>
                                    <div className="subscription_end_date_edit_calender form_input_blk float-none">
                                        <div className="col-md-6 p-0">
                                            <div className="form-group">
                                                <input onChange={this.changeHandler} className="form-control datepicker" id="the_date" type="text" name="" />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a onClick={this.saveChange} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg">{this.state.submitButton ? "Saving..." : "Save Change"}</a> &nbsp;
                                                <a onClick={this.closeChangeDate} className="ml_15 font_16 text_black fw_ar_reg text_underline cursor-pointer">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                }
            </Fragment>
        );
    }
}

export default SubscriptionCancellationBillingUpdate;
