import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT } from "../../../Constants/AppConstants";
import Pagination from '../../Common/Pagination';
import { Row, Col } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import classnames from 'classnames';

class Subscriptions extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            subscriptions: [],
            error_meg: '',
            subscription_type: '',
            filter_loading: false,
            // Pagination Config
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            status: ''
        }
        document.title = "Subscriptions -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        document.getElementById("pageTitle").innerHTML = "Subscriptions";
        const url = new URL(window.location.href);
        const status = url.searchParams.get("status");
        if (status) {
            this.setState({
                status
            });
        }
        this.getAllSubscriptions(this.state.pagenum, status);
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        this.getAllSubscriptions(pageNumber, this.state.status);
    }

    getAllSubscriptions = (pageNumber, status) => {
        let data = {
            pagenum: parseInt(pageNumber),
            status: status,
            subscription_type: this.state.subscription_type,
        }
        AJAX_REQUEST("POST", "subscription/getItems", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    filter_loading: false,
                    subscriptions: results.response.data.subscriptions,
                    // Pagination Config
                    total_records: parseInt(results.response.data.total_records),
                    total_page: parseInt(results.response.data.total_page),
                    per_page: parseInt(results.response.data.per_page),
                    pagenum: parseInt(results.response.data.pagenum),
                    error_meg: results.response.message,
                });
            } else {
                this.setState({
                    loading: false,
                    filter_loading: false,
                    error_meg: results.response.message,
                    subscriptions: [],
                    // Pagination Config
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                });
            }
        });
    }

    onSubmitHandler = e => {
        e.preventDefault();
        const subscription_type = document.getElementById("subscription_type").value;
        this.setState({
            loading: true,
            filter_loading: true,
            subscription_type: subscription_type,
        });
        this.getAllSubscriptions(1, this.state.status);
    };

    render() {
        return (
            <Fragment>
                <div className="woocommerce-MyAccount-content inner_content">
                    {
                        (this.state.loading) ?
                            <div className="loading"></div>
                            :
                            <Fragment>
                                <h3 className="font_16 mb_26 text_gray fw_ar_bold">Invoice History</h3>
                                <div className="table-responsive">
                                    <table className="w-100 subscribtion_table">
                                        <thead>
                                            <th>Subscription Name</th>
                                            <th>Order Type</th>
                                            <th>Date</th>
                                            <th>Total</th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.subscriptions.length <= 0) ?
                                                    <tr><td className="text-center" colSpan="6">{this.state.error_meg}</td></tr>
                                                    :
                                                    this.state.subscriptions.map(function (subscription, key) {
                                                        return (
                                                            <Fragment key={'s' + key}>
                                                                <tr>
                                                                    <td> <NavLink className="font_16 text_black fw_ar_bold let_spa_2" to={`/my-account/related-subscription/${subscription.hasOwnProperty('subscription_id') ? subscription.subscription_id : ''} `}> {'#'}
                                                                        {subscription.hasOwnProperty('subscription_id') ? subscription.subscription_id : ''}
                                                                    </NavLink></td>
                                                                    <td>
                                                                        {/* <span className="d-block  font_16 fw_ar_reg items_text">Order Type</span> */}
                                                                        <span className="d-block  font_16 fw_ar_reg text_black">{(subscription.subscription_type == 'supplement') ? 'Product' : 'Meal'}</span>
                                                                    </td>
                                                                    <td>
                                                                        {/* <span className="d-block  font_16 fw_ar_reg items_text">Date</span> */}
                                                                        <span className="d-block  font_16 fw_ar_reg text_black">{subscription.hasOwnProperty('next_payment') ? subscription.next_payment : ''}</span>
                                                                    </td>
                                                                    <td>
                                                                        {/* <span className="d-block  font_16 fw_ar_reg items_text">Total</span> */}
                                                                        <span className="d-block  font_16 fw_ar_reg text_black">{subscription.hasOwnProperty('subscription_total') ? CURRENCY_FORMAT(subscription.subscription_total) : ''} </span>
                                                                    </td>
                                                                    <td><span className={classnames("d-flex ml-auto align-items-center font_16 text_white let_spa_2 fw_ar_reg status_active", { 'active': subscription.status == 'active', 'inactive': subscription.status == 'inactive', 'failed': subscription.status == 'failed', 'onhold': subscription.status == 'on-hold' })}>{subscription.hasOwnProperty('status') ? subscription.status : ''}</span></td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                            }
                                        </tbody>

                                    </table>
                                </div>
                                <Pagination
                                    pagenationHandle={this.pagenationHandle}
                                    total_records={this.state.total_records}
                                    total_page={this.state.total_page}
                                    per_page={this.state.per_page}
                                    pagenum={this.state.pagenum}
                                />
                            </Fragment>
                    }

                    <div className="woocommerce-notices-wrapper"></div>
                </div >
            </Fragment >

        );
    }
}

export default Subscriptions;