import React, { Fragment, PureComponent } from 'react';
import {CURRENCY_FORMAT} from "../../../Constants/AppConstants";
import ReactImageFallback from "react-image-fallback";

class SubscriptionCartProductList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        
        let product = this.props.product;

        return ( 
            <Fragment>
                 <div className="cart_pro_list d-flex align-items-center justify-content-between aff_cart_pro_list" key={product.row_id}>
                    <div className="cart_pro_list_left d-flex align-items-center">
                        <div className="cart_pro_img">
                        <ReactImageFallback
                                src={product.subs_image}
                                fallbackImage={require('../../../Assets/images/preloader.gif')}
                                initialImage={require('../../../Assets/images/preloader.gif')}
                                alt=''
                                className="cart_product_img" />
                        </div>
                        <div className="cart_pro_det pl_13">
                            <h4 className="font_16 text_black let_spa_2 fw_el_bold m-0 line_height_25"> {product.subs_product_name} x {product.quantity}</h4>
                            <span className="d-block items_text font_12 mb_15 fw_ar_reg">{product.cart_variation_name}</span>
                            { 
                            (this.props.deleteItem=="false")? null :
                            <a onClick={(e) => product.deleteItem(e, product.row_id)} href="#" className="font_12 d-flex red_text fw_ar_reg">Delete</a>
                        }
                        </div>
                    </div>
                    <div className="cart_total">
                        <h4 className="font_16 m-0 text_black let_spa_2 text-uppercase fw_ar_bold" data-title="total">
                        {CURRENCY_FORMAT(product.subs_discount_price)}</h4>
                    </div>
                </div>              
            </Fragment>
         );
    }
}
 
export default SubscriptionCartProductList;