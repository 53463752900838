import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from "jquery";
import Parser from 'html-react-parser';
import history from '../../history';
import { logout } from '../../Store/actions/loginActions';
import { AJAX_PUBLIC_REQUEST, AJAX_REQUEST, REFER_URL, PUBLIC_URL, CUSTOMER_URL, DISTRIBUTOR_URL, GET_STORAGE } from '../../Constants/AppConstants';
import CustDistMenu from './MenuInclude/CustDistMenu';
import CustReferMenu from './MenuInclude/CustReferMenu';
import CustPublicMenu from './MenuInclude/CustPublicMenu';
// import PrestigeLogo from './../../Assets/images/prestige_logo.svg'
import PrestigeLogo from './../../Assets/images/new_prestige_logo.svg'
import { Row, Col } from 'react-bootstrap';
import SearchIcon from './../../Assets/images/public_images/header_search_icon.svg'
import MyAccount from './../../Assets/images/customer_images/my_account_icon.svg'
import CartIcon from './../../Assets/images/customer_images/header_cart_icon.svg'
import MenuDropdown from './../../Assets/images/customer_images/menu_dropdown.svg'
import BestSeller from './../../Assets/images/customer_images/best_sellers_img.svg'
import Basecollect from './../../Assets/images/customer_images/base_collection_img.svg'
import BoostCollect from './../../Assets/images/customer_images/boost_collection_img.svg'
import Bundles from './../../Assets/images/customer_images/bundles_icon.svg'
import BalanceCollect from './../../Assets/images/customer_images/balance_col_img.svg'
import Protein from './../../Assets/images/customer_images/protein_img.svg'
import Workout from './../../Assets/images/customer_images/pro_workout_img.svg'
import WeightLoss from './../../Assets/images/customer_images/weight_loss_img.svg'
import BundleMuscle from './../../Assets/images/customer_images/build_muscle_img.svg'
import EveryDayHealth from './../../Assets/images/customer_images/everyday_helth_img.svg'
import QuestionImg from './../../Assets/images/customer_images/question_img.svg'
import Chart3d from './../../Assets/images/customer_images/chart_3d_img.svg'
import ContactImg from './../../Assets/images/customer_images/contactus_img.svg'
import FaqImg from './../../Assets/images/customer_images/faq_img.svg'
import AffLab from './../../Assets/images/customer_images/aff_lab.svg'
import classNames from 'classnames';

class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showusermenu: false,
            page: '',
            menus: [],
        }

        setInterval(function () {
            if (this.props.auth) {
                if (this.props.auth.user) {
                    if (this.props.auth.user.remember) {
                        AJAX_REQUEST("POST", "user/updateAccessToken", {}).then(results => {
                            if (parseInt(results.response.code) === 1000) {
                                // console.log(results.response.code);
                            }
                        });
                    }
                }
            }
        }.bind(this), 540000);
    }

    showMenu = () => {
        this.setState({
            showusermenu: this.state.showusermenu ? false : true
        });
    }

    showMobSideMenu = () => {
        $("body").toggleClass("current", 1000);
    }

    logout = (e) => {
        e.preventDefault();
        AJAX_REQUEST("POST", "user/logout", {}).then(results => {
            /*if (parseInt(results.response.code) === 1000) { } else {
                // console.log(results.response.message);
            }*/
        });
        this.props.logout();
    }

    componentDidMount() {
        if (this.props.auth && this.props.auth.isAuthenticated) {
            AJAX_REQUEST("POST", "menu/getMenuInfo", { type: 'primary', roles: this.props.auth.user.roles, active_site: this.props.auth.user.site }).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({
                        menus: results.response.data,
                    });
                }
            });
        } else {
            AJAX_PUBLIC_REQUEST("POST", "menu/getMenuInfo", { type: 'primary' }).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({
                        menus: results.response.data,
                    });
                }
            });
        }

        AJAX_PUBLIC_REQUEST("POST", "page/getContactInfo", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    page: results.response.data,
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth) {
            if (this.props.auth.isAuthenticated) {
                // AJAX_REQUEST("POST", "menu/getMenuInfo", { type: 'primary' }).then(results => {
                //     if (parseInt(results.response.code) === 1000) {
                //         this.setState({
                //             menus: results.response.data,
                //         });
                //     }
                // });
                // AJAX_REQUEST("POST", "page/getContactInfo", {}).then(results => {
                //     if (parseInt(results.response.code) === 1000) {
                //         this.setState({
                //             page: results.response.data,
                //         });
                //     }
                // });
            }
        }
    }

    render() {
        const { user } = this.props.auth;
        const email = this.state.page.hasOwnProperty('email') ? Parser(this.state.page.email) : '';
        const phone = this.state.page.hasOwnProperty('phone') ? Parser(this.state.page.phone) : '';

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        let meal_menu_active = true;
        let enable_new_signup = true;
        // if(settings && settings.enable_new_signup == "yes"){
        //     enable_new_signup = true;
        // }
        // if(settings && settings.meal_menu_public == "yes"){
        //     meal_menu_active = true;
        // }else{
        //     if(this.props){
        //         if(this.props.auth){
        //             if(this.props.auth.user){
        //                 if(this.props.auth.user.meal_menu_activated){
        //                     meal_menu_active = true;
        //                 }
        //             }
        //         }
        //     }
        // }

        return (
            <Fragment>
                <header className="montserrat site-header header_blk">
                    <div className="mob_menu_wrapper d-sm-block d-md-none">
                        <div className='site_menu_wrapper_inner headere_mobile'>
                            <div className="mob_site_menu" onClick={this.showMobSideMenu}>
                                <div className="main_menu_blk">
                                    <ul className="mob_site_content">
                                        <li className="menu_close d-flex justify-content-end">
                                            <span className="d-block font_20 text_black fw_ar_bold" onClick={this.showMobSideMenu}>&times;</span>
                                        </li>
                                        {
                                            this.props.auth.isAuthenticated ?
                                                ""
                                                :
                                                <Fragment>
                                                    {
                                                        window.location.pathname == "/login" ? <li className="login_li" key={Math.random()}>
                                                            <NavLink activeClassName="active" style={{ marginRight: '0' }} className="menu_item  fw_ar_reg font_16 text_black d-flex" to="/login" exact >Login</NavLink>
                                                        </li> : null
                                                    }
                                                </Fragment>
                                        }
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL}>HOME</a></li>
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'products'}>PRODUCTS</a></li>
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'athletes'}>ATHLETES</a></li>
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'page/our-story'}>OUR STORY</a></li>
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'contact'}>CONTACT</a></li>
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'page/faq-info'}>FAQ</a></li>
                                        <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL}>SHOP</a></li>
                                        {/* Shop HTML */}
                                        {/* <li><a className="menu_item" href={REFER_URL}>Shop</a></li> */}
                                        {/* <li class="dropdown position-static header_sub_menu">
                                            <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>SHOP</span><img src={MenuDropdown} alt="dropDown" /></a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center">
                                                        <Col md={10}>
                                                        <Row className="shop_menu">
                                                        <Col className="w-20">
                                                                    <a href={`${REFER_URL}top-sellers`} className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/efa_img.png")} alt="best seller" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Best Sellers</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href={`${REFER_URL}bundle-product`} className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Bundles} alt="bundles" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                    </a>
                                                                </Col>
                                                        <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Basecollect} alt="Base Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Base Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={BoostCollect} alt="Boost Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Boost Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={BalanceCollect} alt="Balance Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Balance Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Protein} alt="Protein" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Protein</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Workout} alt="Pre/Intra Workout" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Pre/Intra Workout</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={WeightLoss} alt="Weight Loss" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Weight Loss</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={BundleMuscle} alt="Build Muscle" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Build Muscle</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={EveryDayHealth} alt="Everyday Health" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Everyday Health</span>
                                                                    </a>
                                                                </Col>
                                                        </Row>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href={`${REFER_URL}products`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href={`${REFER_URL}best-sellers`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Best Sellers</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href={`${REFER_URL}bundle-product`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Bundles</a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li> */}
                                        {/* Shop HTML Ends */}

                                        {/* Get Started HTML */}
                                        {/* <li class="dropdown position-static header_sub_menu">
                                            <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>GET STARTED</span><img src={MenuDropdown} alt="dropDown" /></a>
                                            <div class="dropdown-menu" aria-labelledby="getSytarted">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center">
                                                        <Col md={12}>
                                                            <Row className="shop_menurow justify-content-center">
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={QuestionImg} alt="Take the Quiz" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Take the Quiz</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href={`${REFER_URL}bundle-product`} className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Bundles} alt="bundles" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Chart3d} alt="chart 3d" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Chat with Prestige Labs Advisor</span>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li> */}
                                        {/* Get Started HTML Ends */}
                                        {/* <li><a className="menu_item" href='#'>TESTIMONIALS</a></li> */}
                                        {/* <li><a className="menu_item" href='/page/about-us'>ABOUT US</a></li> */}
                                        {/* <li><a className="menu_item" href='https://shop.prestigelabs.com/blogs/events' target="_blank">BLOG</a></li> */}
                                        {/* Support HTML */}
                                        {/* <li class="dropdown position-static header_sub_menu">
                                            <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>Support</span><img src={MenuDropdown} alt="dropDown" /></a>
                                            <div class="dropdown-menu" aria-labelledby="getSytarted">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center">
                                                        <Col md={12}>
                                                        <Row className="shop_menu justify-content-center">
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="/contact" className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Contact</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="/page/faq-info" className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">FAQs</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="https://affiliate.prestigelabs.com/login" className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Become a Prestige Labs Affiliate</a>
                                                        </Col>
                                                        <Col className="w-20">
                                                                    <a href="/contact" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/customer_images/Contact_Us.png")} alt="contact img" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Contact Us</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="/page/faq-info" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/customer_images/FAQ.png")} alt="faq" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">FAQs</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="https://affiliate.prestigelabs.com/login" target="_blank" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require('../../Assets/images/customer_images/Become_an_Affiliate.png')} alt="aff lab img" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Become a Prestige Labs Affiliate</span>
                                                                    </a>
                                                                </Col>
                                                        </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li> */}
                                        {/* Support HTML Ends */}

                                    </ul>
                                </div>

                            </div>

                            {
                                this.props.auth.isAuthenticated ?
                                    <Fragment>
                                        <div className="header_right_blk">
                                            <NavLink to="/my-account" exact strict className="mobile_logo_blk">
                                                <img src={PrestigeLogo} alt="Prestige Labs" />
                                            </NavLink>
                                            <ul className="right_blk_menu d-flex align-items-center">
                                                {/* <li>
                                                    <a href="#">
                                                        <img src={SearchIcon} alt="searchIcon" />
                                                    </a>
                                                </li> */}
                                                <li class="dropdown">
                                                    <span activeClassName="active" className="menu_item d-flex cursor-pointer" exact id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={MyAccount} alt="MyAccount" /></span>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <NavLink class="font_16 text_black fw_el_reg" to="/my-account">My Account</NavLink>
                                                        <a class="font_16 text_black fw_el_reg" onClick={this.logout} style={{ marginRight: '0' }} href="#"> Logout</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Fragment>
                                    :
                                    ""
                            }
                            {/* <li>
                                        <NavLink to="/cart">
                                            <img src={CartIcon} alt="CartIcon" />
                                        </NavLink>
                                    </li> */}
                            <form action="" className="header_search_blk position-relative tab_hide">
                                <input type="search" name="" className="form-control" id="" placeholder="Search" />
                                <img src={SearchIcon} alt="search_icon" className="position-absolute" />
                            </form>

                        </div>
                    </div>


                    <div className="hide_small_screen">
                        <div className="container-fluid">
                            <div className="logo-wrapper">
                                <div className="d-flex align-items-center justify-content-between">
                                    {/* logo HTML */}
                                    <div className="site-logo">
                                        <NavLink title="Prestige  Labs" activeClassName='active' to="/my-account" exact>
                                            <img src={PrestigeLogo} className="attachment-full size-full" alt="" title="" />
                                        </NavLink>
                                    </div>
                                    {/* logo HTML Ends */}

                                    {/* menu HTML */}
                                    <div className="main_menu_blk">
                                        <ul id="menu-main-menu" className="menu d-flex align-items-center">
                                            {
                                                enable_new_signup ?
                                                    <Fragment>
                                                        {
                                                            this.props.auth.isAuthenticated ?
                                                                <Fragment>
                                                                    {
                                                                        ((user.roles != undefined) && (Object.values(user.roles).includes('master_affiliate') || Object.values(user.roles).includes('distributor') || Object.values(user.roles).includes('team_member'))) ?
                                                                            <CustDistMenu menus={this.state.menus} />
                                                                            :
                                                                            <Fragment>
                                                                                {
                                                                                    user.site ?
                                                                                        <Fragment>
                                                                                            {
                                                                                                user.site === 'refer' ?
                                                                                                    <CustReferMenu menus={this.state.menus} />
                                                                                                    : <CustPublicMenu menus={this.state.menus} />
                                                                                            }
                                                                                        </Fragment>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                            </Fragment>
                                                                    }
                                                                </Fragment>
                                                                :
                                                                ''
                                                        }
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {
                                                            (this.state.menus.length <= 0) ? null :
                                                                this.state.menus.map(function (menu, key) {
                                                                    if (menu.type === "external") {
                                                                        if (menu.manual_position != 'last') {
                                                                            return (<li key={'dm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}>{menu.label}</a></li>)
                                                                        }
                                                                    } else {
                                                                        return (<li key={'dm' + key}><NavLink activeClassName="active" className="menu_item" to={menu.url} exact>{menu.label}</NavLink></li>)
                                                                    }
                                                                })
                                                        }

                                                        {
                                                            (this.props.auth.isAuthenticated && Object.values(user.roles).includes('distributor')) ?
                                                                <li key={Math.random()}><a activeClassName="active" className="menu_item" href={DISTRIBUTOR_URL + 'my-account'} ><span>{enable_new_signup ? 'Affiliate Dashboard' : 'Affiliate Panel'}</span></a></li>
                                                                : ''
                                                        }

                                                        {
                                                            (this.props.auth.isAuthenticated && Object.values(user.roles).includes('team_member')) ?
                                                                <li key={Math.random()}><a activeClassName="active" className="menu_item" href={DISTRIBUTOR_URL + 'my-account'} ><span>Team Member Panel</span></a></li>
                                                                : ''
                                                        }

                                                        {
                                                            (this.props.auth.isAuthenticated && Object.values(user.roles).includes('master_affiliate')) ?
                                                                <li key={Math.random()}><a activeClassName="active" className="menu_item" href={DISTRIBUTOR_URL + 'my-affiliate-account'} ><span>{enable_new_signup ? 'Master Affiliate Dashboard' : 'Master Affiliate Panel'}</span></a></li>
                                                                : ''
                                                        }

                                                        {
                                                            user.site ?
                                                                <Fragment>
                                                                    {
                                                                        user.site === 'refer' ?
                                                                            <li key={Math.random()}><a className="menu_item" href={REFER_URL}>Shop Refer</a></li>
                                                                            : <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL}>Shop Public</a></li>
                                                                    }
                                                                </Fragment>
                                                                :
                                                                ''
                                                        }

                                                        {
                                                            (this.state.menus.length <= 0) ? null :
                                                                this.state.menus.map(function (menu, key) {
                                                                    if (menu.type === "external") {
                                                                        if (menu.manual_position == 'last') {
                                                                            return (<li key={'dm' + key}><a target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url ? ' active' : ''}`} href={menu.url}>{menu.label}</a></li>)
                                                                        }
                                                                    }
                                                                })
                                                        }
                                                    </Fragment>
                                            }
                                        </ul>
                                    </div>
                                    {/* menu HTML - Ends */}

                                    <div className="header_right_blk">
                                        <ul className="right_blk_menu d-flex align-items-center">
                                            {
                                                this.props.auth.isAuthenticated ?
                                                    <Fragment>
                                                        {/* <li>
                                                            <form action="" className="header_search_blk position-relative">
                                                                <input type="search" name="" className="form-control" id="" placeholder="Search" />
                                                                <img src={SearchIcon} alt="search_icon" className="position-absolute" />
                                                            </form>
                                                        </li> */}
                                                        {/* <li>
                                                            <a href="#">
                                                                <img src={SearchIcon} alt="searchIcon" />
                                                            </a>
                                                        </li> */}
                                                        <li class="dropdown">
                                                            <span activeClassName="active" className="menu_item d-flex cursor-pointer" exact id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={MyAccount} alt="MyAccount" /></span>
                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <NavLink class="font_16 text_black fw_el_reg" to="/my-account">My Account</NavLink>
                                                                <a class="font_16 text_black fw_el_reg" onClick={this.logout} style={{ marginRight: '0' }} href="#"> Logout</a>
                                                            </div>
                                                        </li>
                                                        {/* <li>
                                                            <a href="#">
                                                                <img src={CartIcon} alt="CartIcon" />
                                                            </a>
                                                        </li> */}


                                                        {/* <li className="pull-right" key={Math.random()}>
                                                                    <a onClick={this.logout} style={{ marginRight: '0' }} className="menu_item" href="#"> Logout</a>
                                                                </li> */}
                                                        {
                                                            !Object.values(user.roles).includes('distributor') ?
                                                                ""
                                                                // <li className="pull-right" key={Math.random()}>
                                                                //     <NavLink activeClassName="active" className="menu_item" to="/my-account" exact > {enable_new_signup ? 'My Orders' : 'My Account'}</NavLink>
                                                                // </li>
                                                                : ''
                                                        }

                                                        {
                                                            this.props.auth.user.activate_meal ?
                                                                <Fragment>
                                                                    {
                                                                        user.site ?
                                                                            <Fragment>
                                                                                {
                                                                                    user.site === 'public' ?
                                                                                        <li key={Math.random()} className="pull-right">
                                                                                            <a className="menu_item" href={PUBLIC_URL + 'activate-meal'} exact > Activate Meal</a>
                                                                                        </li>
                                                                                        :
                                                                                        <Fragment>
                                                                                            {
                                                                                                user.site === 'refer' ?
                                                                                                    <li key={Math.random()} className="pull-right">
                                                                                                        <a className="menu_item" href={REFER_URL + 'activate-meal'} exact > Activate Meal</a>
                                                                                                    </li>
                                                                                                    : ''
                                                                                            }
                                                                                        </Fragment>
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            ''
                                                                    }
                                                                </Fragment>
                                                                : ''
                                                        }
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {
                                                            window.location.pathname !== "/login" ? <li className="pull-right" key={Math.random()}>
                                                                <NavLink activeClassName="active" style={{ marginRight: '0' }} className="menu_item buy_now fw_ar_reg font_16 text_black d-flex active" to="/login" exact > Login</NavLink>
                                                            </li> : null
                                                        }
                                                    </Fragment>
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <section id="scrollTop">
                            {/* <div className="site_menu">
                                <div className="header-logo-fix">
                                    <NavLink to="/my-account" exact strict>
                                        <img src={require("../../Assets/images/logo_fix.png")} alt="Prestige  Labs" title="" />
                                    </NavLink>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </section>
                    </div>
                </header>
            </Fragment>
        );
    }
}

Header.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, { logout })(Header);
