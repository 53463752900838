import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE } from "../../../Constants/AppConstants";
import history from '../../../history';
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import RelatedOrders from './RelatedOrders';
import moment from "moment";
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';

class SubscriptionCancellation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            subscription_id: parseInt(this.props.match.params.subscription_id),
            showCancellation: false,
        }
        document.title = "Subscription Cancellation -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        document.getElementById("pageTitle").innerHTML = "SUBSCRIPTION #" + this.state.subscription_id;
        this.setState({ loading: false })
    }

    showCancellation = (e) => {
        this.setState({ showCancellation: true });
    }

    render() {

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="MyAccount-content">
                            <section className="woocommerce-order-details">
                                <div className="d-flex align-items-center mb_15 justify-content-between">
                                    <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">SUBSCRIPTION CANCELLATION</h2>
                                    {
                                        (this.state.showCancellation) ?
                                            <Fragment>
                                                <NavLink to={`/my-account/subscription-cancellation/${this.state.subscription_id}/cancellation`} className="text_black font_16 fw_ar_reg text_underline">CONTINUE TO CANCEL</NavLink>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <button onClick={() => this.showCancellation()} className="border_btn text_black font_16 fw_ar_reg text_underline">CONTINUE TO CANCEL</button>
                                            </Fragment>
                                    }
                                </div>
                                <div className='sub_billing_blk white_bg'>
                                    {
                                        (this.state.showCancellation) ?
                                            <Fragment>

                                                <p className='font_16 mb_15 text_black fw_ar_reg'>
                                                    Do you want to change your shipping address, push out your next delivery, or change your billing information?
                                                    We can help with that by clicking the corresponding button below.
                                                </p>
                                                <div className="subscription_sub_cancellation d-flex align-items-center can_btn_ul">
                                                    <NavLink className="font_16 text_black fw_ar_reg text_underline" to={`/my-account/change-subscription-address/${this.state.subscription_id}`}>UPDATE SHIPPING ADDRESS</NavLink>
                                                    <NavLink className="font_16 text_black fw_ar_reg text_underline" to={`/my-account/subscription-cancellation/${this.state.subscription_id}/billing-update`}>CHANGE PAYMENT DATE</NavLink>
                                                    <NavLink className="font_16 text_black fw_ar_reg text_underline" to={`/my-account/my-cards`}>UPDATE PAYMENT INFORMATION</NavLink>
                                                </div>

                                            </Fragment>
                                            :
                                            <Fragment>
                                                <p className='font_16 mb_15 text_black fw_ar_reg'>
                                                    Would you like to change the products you are receiving (the flavors and/or adding/subtracting items)?
                                                    We can help with that by clicking either button below.
                                                </p>
                                                <div className="customer_sub_cancellation">
                                                    <NavLink to={`/my-account/subscription-cancellation/${this.state.subscription_id}/details`} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg">Change Product</NavLink>
                                                </div>
                                            </Fragment>
                                    }
                                </div>

                            </section>
                        </div>
                }
            </Fragment>
        );
    }
}

export default SubscriptionCancellation;