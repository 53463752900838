import React, { Fragment, PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { AJAX_REQUEST, ITEM_COUNT_SUSBSCRIPTION } from "../../../Constants/AppConstants";
import $ from "jquery";
import SearchIcon from '../../../Assets/images/customer_images/header_search.svg'
import SubscriptionItemListWithFlavors from "./SubscriptionItemListWithFlavors";
import SubscriptionItemList from "./SubscriptionItemList";

class SubscriptionItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            products_filtered: [],
            searchName: '',
            cart_count: 0,
            loading: true
        }
        document.title = "Subscription Item -Prestige Labs";
    }

    itemCount = (e) => {
        let count = ITEM_COUNT_SUSBSCRIPTION();
        this.setState({ cart_count: count });
    }

    componentDidMount() {
        document.getElementById("scrollTop").scrollIntoView() // Scroll to Top
        this.getAllProducts();
        this.itemCount();
    }

    getAllProducts = () => {
        AJAX_REQUEST("POST", "product/getList", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    products: results.response.data,
                    products_filtered: results.response.data,
                    loading: false
                });
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    filterProduct = (e) => {
        this.setState({
            searchName: e.target.value,
            products_filtered: this.state.products.filter(function (product) {
                if (e.target.value === '') {
                    return product;
                } else {
                    let string = product.title.toUpperCase();
                    let substring = e.target.value.toUpperCase();
                    if (string.includes(substring)) {
                        return product;
                    }
                }
            }.bind(this))
        });
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="order_container">
                                <div className="container">
                                    <div className="d-flex align-items-center mb_28 justify-content-between">
                                        <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi m-0">Product Lists</h2>
                                        <form className="product-search-form product_search_input position-relative form_input_blk">
                                            <div className="search-input-group">
                                                <input onChange={this.filterProduct} value={this.state.searchName}
                                                    className="form-control" placeholder="Search" name="searchName" id="filter-by" autoComplete="off" type="text" />
                                            </div>
                                            <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                <img src={SearchIcon} alt="search icon" />
                                            </div>
                                        </form>
                                    </div>

                                    {
                                        (this.state.products_filtered.length <= 0) ? <tr><td className="text-center" colSpan="3">Product did not Matched!</td></tr> :
                                            this.state.products_filtered.map(function (product, key) {

                                                if (product.subscription == 'yes') {
                                                    // Added Subscription item_count
                                                    product.item_count = this.itemCount

                                                    return (
                                                        <Fragment key={product.product_id}>
                                                            {
                                                                product.hasOwnProperty('flavors') ?
                                                                    <SubscriptionItemListWithFlavors product={product} />
                                                                    :
                                                                    <SubscriptionItemList product={product} />
                                                            }
                                                        </Fragment>
                                                    )
                                                }

                                            }.bind(this))
                                    }
                                    <div className="shopping-cart-wrapper">
                                        <NavLink to={`/subscription-cart/${this.props.match.params.id}`} title="Shopping cart">
                                            <div className="shopping-cart">
                                                <span id="expressCartItemCount" className="item-count"> {this.state.cart_count} </span> item(s)
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default SubscriptionItem;