import React, { PureComponent } from 'react';
import { NavLink } from "react-router-dom";
import { AJAX_REQUEST, GET_STORAGE } from "../../../Constants/AppConstants";
import { Row, Col } from 'react-bootstrap';
import TotalOrder from './../../../Assets/images/customer_images/total_order.svg'
import ActiveSub from './../../../Assets/images/customer_images/active_subscription.svg'
import TotalSub from './../../../Assets/images/customer_images/total_subscription.svg'
import productImage from '../../../Assets/images/customer_images/dashboard_img.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import LikedProImg1 from './../../../Assets/images/refer_images/like_product_1.svg'
import LikedProImg2 from './../../../Assets/images/refer_images/like_product_2.svg'
import LikedProImg3 from './../../../Assets/images/refer_images/like_product_3.svg'
import LikedProImg4 from './../../../Assets/images/refer_images/like_product_4.svg'

const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 4.5,

        }
    },
};
class Dashboard extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            userInfo: [],
            errorMeg: '',
            role: 'customer'
        }

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }
        if (settings && settings.enable_new_signup == "yes") {
            document.title = "My Orders -Prestige Labs";
        } else {
            document.title = "My Account -Prestige Labs";
        }

    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }
        if (settings && settings.enable_new_signup == "yes") {
            document.getElementById("pageTitle").innerHTML = "My Orders";
        } else {
            document.getElementById("pageTitle").innerHTML = "My Account";
        }
        this.getUserInfo();
    }

    getUserInfo = () => {
        AJAX_REQUEST("POST", "customer/dashboard", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    userInfo: results.response.data
                });
            } else {
                this.setState({
                    loading: false,
                    errorMeg: results.response.message
                })
            }
        });
    }

    
    render() {
        return (
            <React.Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading"></div>
                        :
                        <React.Fragment>
                            <div className="dashbored_blk">
                                <Row className='dashboard_row'>
                                    <Col md={4}>
                                        <NavLink to={`/my-account/orders`} className="dashboar_col d-flex align-items-center justify-content-between">
                                            <div className="left_dash">
                                                <h4 className="font_16 text_black mb_4 fw_ar_bold">Total Orders</h4>
                                                <h1 className="m-0 font_36 text_black fw_ar_bold">{this.state.userInfo.hasOwnProperty('total_orders') ? this.state.userInfo.total_orders : ''}</h1>
                                            </div>
                                            <img src={TotalOrder} alt="total order" />
                                        </NavLink>
                                    </Col>
                                    <Col md={4}>
                                        <NavLink to={`/my-account/subscriptions`} className="dashboar_col d-flex align-items-center justify-content-between">
                                            <div className="left_dash">
                                                <h4 className="font_16 text_black mb_4 fw_ar_bold">Total Subscriptions</h4>
                                                <h1 className="m-0 font_36 text_black fw_ar_bold">{this.state.userInfo.hasOwnProperty('total_subscriptions') ? this.state.userInfo.total_subscriptions : ''}</h1>
                                            </div>
                                            <img src={TotalSub} alt="total order" />
                                        </NavLink>
                                    </Col>
                                    <Col md={4}>
                                        <NavLink to={`/my-account/subscriptions?status=active`} className="dashboar_col d-flex align-items-center justify-content-between">
                                            <div className="left_dash">
                                                <h4 className="font_16 text_black mb_4 fw_ar_bold">Active Subscriptions</h4>
                                                <h1 className="m-0 font_36 text_black fw_ar_bold">{this.state.userInfo.hasOwnProperty('active_subscriptions') ? this.state.userInfo.active_subscriptions : ''}</h1>
                                            </div>
                                            <img src={ActiveSub} alt="total order" />
                                        </NavLink>
                                    </Col>
                                     {/* <Col md={12}>
                                        <h2 className="font_18 m-0 text_black fw_ar_bold">Recent Orders</h2>
                                        <div className="table-responsive mb_12">
                                            <table className="w-100 subscribtion_table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                <div className="pl_16">
                                                                    <h5 className="font_16 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'>1 X</span> AM Formula</h5>
                                                                    <span className="font_12 items_text d-block fw_ar_reg">R.I.P. Fat -- Get RIPPED</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_12 fw_ar_reg items_text">Ordered On</span>
                                                            <span className="d-block  font_12 fw_ar_bold text_black let_spa_2">17 March 2023</span>
                                                        </td>
                                                        <td>
                                                            <span className="status_active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">Delivered</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_14 fw_ar_bold text_black let_spa_2">$95.72 </span>
                                                        </td> */}
                                                        {/* <td><a className="font_14 text_black let_spa_2 fw_ar_reg text_underline" href='#'>Re-Order</a></td> */}
                                                    {/* </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                <div className="pl_16">
                                                                    <h5 className="font_16 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'>1 X</span> AM Formula</h5>
                                                                    <span className="font_12 items_text d-block fw_ar_reg">R.I.P. Fat -- Get RIPPED</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_12 fw_ar_reg items_text">Ordered On</span>
                                                            <span className="d-block  font_12 fw_ar_bold text_black let_spa_2">17 March 2023</span>
                                                        </td>
                                                        <td>
                                                            <span className="status_active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">Delivered</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_14 fw_ar_bold text_black let_spa_2">$95.72 </span>
                                                        </td> */}
                                                        {/* <td><a className="font_14 text_black let_spa_2 fw_ar_reg text_underline" href='#'>Re-Order</a></td> */}
                                                    {/* </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                <div className="pl_16">
                                                                    <h5 className="font_16 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'>1 X</span> AM Formula</h5>
                                                                    <span className="font_12 items_text d-block fw_ar_reg">R.I.P. Fat -- Get RIPPED</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_12 fw_ar_reg items_text">Ordered On</span>
                                                            <span className="d-block  font_12 fw_ar_bold text_black let_spa_2">17 March 2023</span>
                                                        </td>
                                                        <td>
                                                            <span className="status_active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">Delivered</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_14 fw_ar_bold text_black let_spa_2">$95.72 </span>
                                                        </td> */}
                                                        {/* <td><a className="font_14 text_black let_spa_2 fw_ar_reg text_underline" href='#'>Re-Order</a></td> */}
                                                    {/* </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                <div className="pl_16">
                                                                    <h5 className="font_16 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'>1 X</span> AM Formula</h5>
                                                                    <span className="font_12 items_text d-block fw_ar_reg">R.I.P. Fat -- Get RIPPED</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_12 fw_ar_reg items_text">Ordered On</span>
                                                            <span className="d-block  font_12 fw_ar_bold text_black let_spa_2">17 March 2023</span>
                                                        </td>
                                                        <td>
                                                            <span className="status_active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">Delivered</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_14 fw_ar_bold text_black let_spa_2">$95.72 </span>
                                                        </td> */}
                                                        {/* <td><a className="font_14 text_black let_spa_2 fw_ar_reg text_underline" href='#'>Re-Order</a></td> */}
                                                    {/* </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="left_pro_de d-flex align-items-center w-50">
                                                                <img src={productImage} alt="productImage" className="product_image" />
                                                                <div className="pl_16">
                                                                    <h5 className="font_16 mb_6 text_black fw_el_bold let_spa_2"><span className='font_12 text_black fw_ar_bold'>1 X</span> AM Formula</h5>
                                                                    <span className="font_12 items_text d-block fw_ar_reg">R.I.P. Fat -- Get RIPPED</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_12 fw_ar_reg items_text">Ordered On</span>
                                                            <span className="d-block  font_12 fw_ar_bold text_black let_spa_2">17 March 2023</span>
                                                        </td>
                                                        <td>
                                                            <span className="status_active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">Delivered</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block  font_14 fw_ar_bold text_black let_spa_2">$95.72 </span>
                                                        </td> */}
                                                        {/* <td><a className="font_14 text_black let_spa_2 fw_ar_reg text_underline" href='#'>Re-Order</a></td> */}
                                                    {/* </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>  */}
                                    {/* <Col md={12}>
                                        <h2 className="font_16 mb_18 let_spa_2 text_black fw_ar_bold">Inspired by your browsing history</h2>
                                        <div className="pro_carousel browsing_his_carousel position-relative">
                                            <OwlCarousel className='owl-theme' {...options}>
                                                <div class='item'>
                                                    <div className="pro_img">
                                                        <img src={LikedProImg1} alt="like product 1" />
                                                    </div>
                                                    <div className="like_pro_des flex-column d-flex align-items-center">
                                                        <h3 className="font_20 text-center text_black fw_el_bold m-0">Sleeper Multiplier</h3>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className="pro_img">
                                                        <img src={LikedProImg2} alt="like product 2" />
                                                    </div>
                                                    <div className="like_pro_des flex-column d-flex align-items-center">
                                                        <h3 className="font_20 text-center text_black fw_el_bold m-0">Am Formula</h3>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className="pro_img">
                                                        <img src={LikedProImg3} alt="like product 3" />
                                                    </div>
                                                    <div className="like_pro_des flex-column d-flex align-items-center">
                                                        <h3 className="font_20 text-center text_black fw_el_bold m-0">PM Formula</h3>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className="pro_img">
                                                        <img src={LikedProImg4} alt="like product 4" />
                                                    </div>
                                                    <div className="like_pro_des flex-column d-flex align-items-center">
                                                        <h3 className="font_20 text-center text_black fw_el_bold m-0">Sleeper Multiplier</h3>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className="pro_img">
                                                        <img src={LikedProImg1} alt="like product 1" />
                                                    </div>
                                                    <div className="like_pro_des flex-column d-flex align-items-center">
                                                        <h3 className="font_20 text-center text_black fw_el_bold m-0">Sleeper Multiplier</h3>
                                                    </div>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </Col> */}
                                </Row>
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default Dashboard;