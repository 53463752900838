import React, { Component, Fragment } from 'react';
import { CURRENCY_FORMAT } from '../../../Constants/AppConstants';
import ReactImageFallback from "react-image-fallback";
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'

class OrderProductDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let product = this.props.product;
        return (
            <Fragment>
                <div className="order_pro_detail d-flex align-items-center justify-content-between">
                    <div className="left_pro_de d-flex align-items-center w-50">
                    <img src={productImage} alt="productImage" className="product_image" />
                        <div className="pl_16">
                            <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2">{product.item_name}</h5>
                            <span className="font_12 items_text d-block fw_ar_reg">Monthly</span>
                        </div>
                    </div>
                    <div className="right_pro d-flex flex-column align-items-end">
                        <h5 className="font_14 text_black let_spa_2 fw_ar_bold m-0">{CURRENCY_FORMAT(product.price)}</h5>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default OrderProductDetails;